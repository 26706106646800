body {
  padding: 2px;
  background: #0f0f0f;
}
.splash {
  width: 150%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  background-size: cover;
  max-width: 100%;
  z-index: 1;
  text-align: center;
}
.splash img {
  height: 100%;
  width: 25%;
  transition: 1s all;
}
@media screen and (min-width: 1440px) {
  .splash {
    margin-top: 2.5%;
  }
  .splash img {
    width: 20%;
  }
}
@media screen and (max-width: 480px) {
  .splash {
    height: auto;
    max-height: 40%;
    top: 0px;
    transition: 1s all;
    max-width: 100%;
    transition: 1s all;
    font-size: 0.75rem;
    margin-top: 15%;
  }
  .splash img {
    width: 50%;
  }
}

.experience {
  text-align: center;
  padding: 0%;
  font-size: 1rem;
}
.experience h1 {
  font-size: 2.5em;
  margin: 0 auto 10px auto;
  color: #ffffff;
  word-break: break-all;
  max-width: 98%;
}
@media screen and (max-width: 480px) {
  .experience h1 {
    font-size: 2em;
  }
}

.songs {
  text-align: center;
  color: #ffffff;
}

.songs ul {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0px;
}
.songs ul li {
  margin: 1px;
  padding: 1px;
}
.songs ul li a {
  color: #ffffff;
  transition: 0.5s all;
}
.songs ul li a:hover {
  color: #b57c48;
}

.link {
  color: orange;
}
.link:hover {
  color: yellow !important;
}
.link:visited {
  color: orange;
}
.link:active {
  color: orange;
}

.white {
  color: white;
}

.copyright {
  font-size: 0.8em;
}
